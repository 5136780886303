import Image from 'next/image'
import numeral from 'numeral'
import { ChangeEvent, useEffect, useMemo, useRef, useState } from 'react'

import { Button } from 'src/components/atoms/Button'
import {
  ButtonLink,
  InternalLink,
  OnClickLink
} from 'src/components/atoms/Link'
import { Slider } from 'src/components/atoms/Slider'
import { ParagraphNoSpace } from 'src/components/atoms/Typography'
import { calculateCarbon } from 'src/components/organisms/CarbonCalculator/helper'
import { CalculatorTab } from 'src/components/organisms/CarbonCalculator/types'

import { carbonCalculatorPath, donatePath } from 'src/config/paths'
import { useMessenger } from 'src/hooks/useMessenger'

type CarbonActivityType = {
  image: string
  value: number
  text: string
}

const activities: CarbonActivityType[] = [
  {
    image: '/img/icons/scenario-flights.svg',
    text: `Equivalent to 22 return flights between Christchurch and Auckland.`,
    value: 10
  },
  {
    image: '/img/icons/scenario-driving.svg',
    text: `Equivalent to a fleet of 12 medium-sized cars driving 20,000 km.`,
    value: 50
  },
  {
    image: '/img/icons/scenario-international-flight.svg',
    text: `Equivalent to 19 return flights between Auckland and London.`,
    value: 150
  }
]

type CarbonActivityProps = {
  activity: CarbonActivityType
}

const CarbonActivity = ({ activity }: CarbonActivityProps) => {
  const { image, text, value } = activity
  return (
    <div className='min-w-[260px] lg:min-w-min p-5 flex-1 flex flex-col bg-linen rounded-[10px]'>
      <Image alt={`activity_co2_${value}`} src={image} width={80} height={70} />
      <span className='text-[14px] leading-[20px] font-medium max-w-full text-wrap'>
        {value}
        {text}
      </span>
    </div>
  )
}

const CalculateFromCo2Emitted = () => {
  const [value, setValue] = useState(50)
  const [getInTouch, setGetInTouch] = useState(false)
  const { showNewMessage } = useMessenger()

  const numberOfTrees = useMemo(() => {
    if (value === 0) return 0
    const res = calculateCarbon({
      formTab: CalculatorTab.Carbon,
      stems: 0,
      emissions: value,
      age: 50,
      trees: 25,
      shrubs: 75,
      includeOwnMeasurements: false,
      standTreeAge: 0,
      standTreeSurvival: 0,
      standTreeHeight: 0,
      standTreeDBH: 0,
      standShrubSurvival: 0,
      standShrubHeight: 0,
      standShrubRCD: 0
    })
    return Math.ceil(res.stems)
  }, [value])

  const inputRef = useRef<HTMLInputElement>(null)

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.style.width = `${value.toString().length}ch`
    }

    setGetInTouch(value > 2500)
  }, [value])

  const handleSliderChange = (e: ChangeEvent<HTMLInputElement>) => {
    const val = Number(e.target.value) || 0
    setValue(val)
  }

  const donateUrl = useMemo(() => {
    return donatePath({
      queryParams: { quantity: numberOfTrees }
    })
  }, [numberOfTrees])

  return (
    <div className='flex flex-col md:flex-row'>
      <div className='flex-1 flex flex-col gap-5 px-[15px] py-5 md:p-10'>
        <label
          htmlFor='value'
          className='items-end flex flex-row px-5 py-[8px] border-[2px] border-[#CFCFCF] focus-within:border-mostlyGreen rounded-[10px]'
        >
          <input
            ref={inputRef}
            type='number'
            min={10}
            step={10}
            className='outline-none text-[34px] leading-[40px] md:text-[54px] md:leading-[60px] font-bold number-no-scroll pe-0'
            value={Number(value).toString()}
            onChange={e => {
              const el = e.currentTarget
              setValue(Number(el.value))
            }}
            id='value'
          />
          <span className='text-[20px] leading-[26px] font-bold'>tCO2e</span>
        </label>
        <div className='flex flex-col gap-[8px]'>
          <Slider
            className={`${getInTouch ? 'text-osloGrey' : 'text-black'}`}
            value={value}
            onChange={handleSliderChange}
            min={10}
            max={2500}
            step={10}
          />
          <div className='text-sm leading-5 font-medium flex flex-row justify-between'>
            <span>10 tCO2e</span>
            <span>2500 tCO2e</span>
          </div>
        </div>
      </div>
      <div className='flex flex-col gap-5 md:gap-10 flex-1 p-[15px] md:p-10 bg-aquaSpring rounded-[20px]'>
        <div className='flex flex-col gap-5'>
          <div className='flex flex-col gap-[10px]'>
            <span className='font-bold text-mostlyGreen text-[18px] leading-[25px] md:text-[20px] md:leading-[26px]'>
              <span className='text-[30px] leading-[30px] tracking-tighter md:text-[54px] md:leading-[60px] md:tracking-tight '>
                {numeral(numberOfTrees).format('0,0')}
              </span>{' '}
              native trees
            </span>
            <span className='text-[14px] md:text-base font-medium'>
              To remove {value} tCO2 equivalent over 50 years of continuous
              growth.
            </span>
          </div>
          {getInTouch ? (
            <Button
              className='self-start'
              size='large'
              onClick={() => {
                showNewMessage()
              }}
            >
              Talk to the team
            </Button>
          ) : (
            <ButtonLink className='self-start' size='large' href={donateUrl}>
              Fund now
            </ButtonLink>
          )}
        </div>
      </div>
    </div>
  )
}

export type CarbonCalculatorWidgetProps = {
  showExamples?: boolean
}

const CarbonCalculatorWidget = ({
  showExamples
}: CarbonCalculatorWidgetProps) => {
  const [showLearnMore, setShowLearnMore] = useState(false)

  return (
    <div className='bg-white flex flex-col gap-5 md:gap-10 items-stretch'>
      <div className='w-full'>
        <CalculateFromCo2Emitted />
      </div>
      {showExamples ? (
        <div className='flex flex-col gap-5'>
          <ParagraphNoSpace className='!m-0 !text-[16px] md:!text-[20px] !leading-[24px] font-semibold'>
            CO₂ emissions from common activities, by New Zealand standards.
          </ParagraphNoSpace>
          <div className='flex flex-row whitespace-nowrap overflow-x-scroll gap-5 content-between'>
            {activities.map((a, idx) => (
              <CarbonActivity key={`activity_${idx}`} activity={a} />
            ))}
          </div>
          {!showLearnMore ? (
            <OnClickLink
              className='!font-medium !text-[14px] self-start'
              color='black-underlined'
              onClick={() => {
                setShowLearnMore(true)
              }}
            >
              Read more about how we calculate these
            </OnClickLink>
          ) : (
            <>
              <ParagraphNoSpace className='!text-[12px] !leading-[14.5px]'>
                The carbon calculator we provide access to is owned, developed
                and offered for use free-of-charge by not-for-profit charity,
                Tāne&apos;s Tree Trust. This carbon calculator is not for use to
                determine an official CO₂ offset, nor to determine benefits or
                liabilities under the New Zealand Emissions Trading Scheme, or
                to make any claims of carbon neutrality. The calculations only
                show the potential of CO₂ absorption based on a default setting
                of trees growing continuously for 50 years.
              </ParagraphNoSpace>
              <ParagraphNoSpace className='!text-[12px] !leading-[14.5px]'>
                <InternalLink className='underline' href={carbonCalculatorPath}>
                  Click here
                </InternalLink>{' '}
                for important info about using the carbon calculator, and how
                Trees That Count calculates CO₂ throughout our website. For more
                information around the carbon calculator, and understanding your
                calculations, visit the Tāne&apos;s Tree Trust website.
              </ParagraphNoSpace>
            </>
          )}
        </div>
      ) : null}
    </div>
  )
}

export default CarbonCalculatorWidget
